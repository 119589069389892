@use 'sats-ui-lib/tokens/light';
@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/corner-radius';

.access-denied {
  background-color: light.$fixed-background-primary-default;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  &__content {
    background-color: light.$fixed-surface-primary-default;
    border-radius: corner-radius.$s;
    padding: spacing.$xxl spacing.$l;
    margin: spacing.$m;
    max-width: 500px;
    align-self: center;
    text-align: center;

    @media (min-width: 600px) {
      padding: spacing.$xxl;
    }
  }

  &__title {
    margin-bottom: spacing.$m;
  }

  &__text {
    margin-bottom: spacing.$l;
  }

  &__button {
    width: 100%;
  }
}
